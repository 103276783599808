<template>
  <el-dialog :visible.sync="visible" :title="form && form.id ? '编辑QQ客服' : '新增QQ客服'" append-to-body :close-on-click-modal="false" width="600px" @closed="form = null">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px" label-suffix=":" v-if="form">
      <el-form-item prop="name" label="客服名称">
        <el-input v-model.trim="form.name" :maxlength="20" clearable />
      </el-form-item>
      <el-form-item prop="qq" label="QQ号码">
        <el-input v-model.trim="form.qq" :maxlength="20" clearable />
      </el-form-item>
      <el-form-item prop="info" label="描述">
        <el-input type="textarea" v-model="form.info" :maxlength="50" resize="none" :rows="3" clearable />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button type="text" @click="visible = false">取消</el-button>
      <el-button :loading="saving" type="primary" @click="doSave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { save } from "@/api/qqservice";

export default {
  data() {
    return {
      visible: false,
      form: null,
      saving: false,
      rules: {
        name: [{ required: true, message: "请填写客服名称" }],
        qq: [{ required: true, message: "请选择QQ号码" }],
      },
    };
  },
  methods: {
    doSave() {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.saving = true;
          save(this.form)
            .then((res) => {
              this.$notify({
                title: "保存QQ客服成功",
                type: "success",
                duration: 2500,
              });
              this.$parent.init();
              this.visible = false;
            })
            .finally((_) => {
              this.saving = false;
            });
        });
    },
    resetForm(form) {
      this.form = form || {
        name: "",
        qq: "",
        info: "",
      };
      this.visible = true;
    },
  },
};
</script>